/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../components/layout';
import SEO from '../components/seo';

const BlogPost = ({ data }) => {
  return (
    <div>mfdksfmlksdfmkmskdflm</div>
  )
  // const {
  //   title, body, image, tags,
  // } = data.contentfulBlogPost;
  // console.log('data.contentfulBlogPost', data.contentfulBlogPost);
  // return (
  //   <Layout>
  //     <SEO title={title} />
  //     <div className="blogpost">
  //       <h1>{title}</h1>
  //       <img alt={title} src={image.file.url} />
  //       <div className="tags">
  //         {tags.map(tag => (
  //           <span className="tag" key={tag}>
  //             {tag}
  //           </span>
  //         ))}
  //       </div>
  //       <p className="body-text">{body.body}</p>
  //       <Link to="/blogposts">View more posts</Link>
  //       <Link to="/">Back to Home</Link>
  //     </div>
  //   </Layout>
  // );
};

BlogPost.propTypes = {
  data: PropTypes.shape({
    contentfulBlogPost: PropTypes.shape({
      title: PropTypes.string,
      body: PropTypes.string,
      image: PropTypes.shape({
        file: PropTypes.shape({
          url: PropTypes.string,
        }),
      }),
      tags: PropTypes.arrayOf(PropTypes.string),
    }),
  }).isRequired,
};

export default BlogPost;

// export const pageQuery = graphql`
//   query($slug: String!) {
//     contentfulBlogPost(slug: { eq: $slug }) {
//       title
//       slug
//       body {
//         body
//       }
//       playlist {
//         file {
//           url
//         }
//       }
//       image {
//         file {
//           url
//         }
//       }
//       tags
//     }
//   }
// `;
